<template>
  <div class="app-container">
    <Title :data="data" />
    <div style="display: flex">
      <div style="width: 80%">
        <batchadd
          title="授课计划"
          text="授课计划"
          :dataList="form.teaching_plan"
          @edit="clickUpload('teaching_plan')"
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="课程标准"
          text="课程标准"
          :dataList="form.course_standard"
          @edit="clickUpload('course_standard')"
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="课程大纲"
          text="课程大纲"
          :dataList="form.course_outline"
          @edit="clickUpload('course_outline')"
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="教学日历"
          text="教学日历"
          :dataList="form.teaching_calendar"
          @edit="clickUpload('teaching_calendar')"
          style="margin-bottom: 20px"
        ></batchadd>
        <batchadd
          title="说课"
          text="说课"
          :dataList="form.lecture"
          @edit="clickUpload('lecture')"
          style="margin-bottom: 20px"
        ></batchadd>
      </div>
      <Uptips style="width: 20%"></Uptips>
    </div>
    <div class="bottom_btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >保存并提交</el-button
      >
    </div>
  </div>
</template>

<script>
// import BatchAdd from "@/components/Batch/index.vue";
import batchadd from "@/components/Batch_add/index.vue";
import request from "@/utils/request";
export default {
  components: {
    batchadd,
  },
  data() {
    return {
      selected: false,
      btnLoading: false,
      list: [],
      listQuery: {
        type: "",
        class_course_id: "",
      },
      form: {
        teaching_plan: {
          class_course_id: null,
          type: null,
          type_key: "teaching_plan",
          data: [],
        },
        course_standard: {
          class_course_id: null,
          type: null,
          type_key: "course_standard",
          data: [],
        },
        course_outline: {
          class_course_id: null,
          type: null,
          type_key: "course_outline",
          data: [],
        },
        teaching_calendar: {
          class_course_id: null,
          type: null,
          type_key: "teaching_calendar",
          data: [],
        },
        lecture: {
          class_course_id: null,
          type: null,
          type_key: "lecture",
          data: [],
        },
      },
      data: null,
      teaching_plan: [],
      course_standard: [],
      course_outline: [],
      teaching_calendar: [],
      lecture: [],
    };
  },
  created() {
    this.listQuery.type = this.$route.query.type;
    // await let data=this.$route.query.data
    this.data = JSON.parse(this.$route.query.data);
    console.log(this.data, "============22222");
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    localStorage.setItem("classdata", JSON.stringify(this.data));
    
    this.form = {
      teaching_plan: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teaching_plan",
        data: [],
      },
      course_standard: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "course_standard",
        data: [],
      },
      course_outline: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "course_outline",
        data: [],
      },
      teaching_calendar: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teaching_calendar",
        data: [],
      },
      lecture: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "lecture",
        data: [],
      },
    };
    this.getDetail();
    console.log(this.form);
  },
  methods: {
    clickUpload(e) {
      switch (e) {
        case "teaching_plan":
          this.form.teaching_plan.is_store = 1;
          break;
        case "course_standard":
          this.form.course_standard.is_store = 1;
          break;
        case "course_outline":
          this.form.course_outline.is_store = 1;
          break;
        case "teaching_calendar":
          this.form.teaching_calendar.is_store = 1;
          break;
        case "lecture":
          this.form.lecture.is_store = 1;
          break;
      }
    },
    getDetail() {
      request({
        url: "/api/teacherend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data.teaching_plan)
          this.form.teaching_plan = response.data.teaching_plan;
        if (response.data.course_standard)
          this.form.course_standard = response.data.course_standard;
        if (response.data.course_outline)
          this.form.course_outline = response.data.course_outline;
        if (response.data.teaching_calendar)
          this.form.teaching_calendar = response.data.teaching_calendar;
        if (response.data.lecture) this.form.lecture = response.data.lecture;
      });
    },
    submit() {
      let submitForm = {};
      if (
        this.form.teaching_plan.is_store != 1 &&
        this.form.course_standard.is_store != 1 &&
        this.form.course_outline.is_store != 1 &&
        this.form.teaching_calendar.is_store != 1
      ) {
        this.$alert("请修改或者上传后提交", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            return;
          },
        });
      }
      if (this.form.teaching_plan.is_store) {
        submitForm.teaching_plan = this.form.teaching_plan;
      }
      if (this.form.course_standard.is_store) {
        submitForm.course_standard = this.form.course_standard;
      }
      if (this.form.course_outline.is_store) {
        submitForm.course_outline = this.form.course_outline;
      }
      if (this.form.teaching_calendar.is_store) {
        submitForm.teaching_calendar = this.form.teaching_calendar;
      }
      if (this.form.lecture.is_store) {
        submitForm.lecture = this.form.lecture;
      }
      if (Object.keys(submitForm).length == 0) {
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/teacherend/eduAdmin/dataStore",
        method: "post",
        data: submitForm,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            message: "提交成功,待审核!",
            type: "success",
          });
          this.getDetail()
          // this.$router.go(0);
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    addList() {
      this.list.push({ name: "" });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
.bottom_btn {
  display: flex;
  justify-content: center;
}
</style>
